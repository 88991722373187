<template>
  <div>
    <HeaderComponent v-on:bientot_disponible="state.showDialog2=true" :hauteur="'h-20'"/>
    <LoaderComponent class="m-auto mt-20" v-if="!state.datas"/>
    <div v-if="state.datas">
<!--    <div class="">-->
<!--      <ResearchComponent v-on:getValueForm="getValueForm" :jobTitle="state.jobSearch" class="-mt-12 m-auto"/>-->
<!--    </div>-->
    <div class="flex sm:m-11 xs:m-0">
      <!--      div de gauche-->
      <!--      Block pour les composants des taille d'ecran md et plus-->
<!--      <div v-bind:style="{background:'#F7F9FD'}" class="flex-1 rounded p-4 hidden md:block">-->
<!--        <header>-->
<!--          <h3 class="font-medium tracking-wider text-gray-500">Compétences</h3>-->
<!--          <div>-->
<!--            <input disabled v-bind:style="{background:'#EBF0F9'}" type="email" name="email"-->
<!--                   class="shadow-sm text-gray-600 block md:text-base w-full h-12 sm:text-sm border-gray-300 rounded focus:ring-indigo-500 focus:border-indigo-500"-->
<!--                   placeholder="Ex: Java..."/>-->
<!--          </div>-->
<!--          <hr class="w-full h-0.5 bg-indigo-500 mt-3">-->
<!--        </header>-->
<!--        <nav class="mt-5">-->
<!--          <h3 class="font-medium tracking-tighter text-gray-500">Type d'emplois</h3>-->
<!--          <fieldset v-for="(data,index) in state.Type_Offre" :key="index" class="space-y-5">-->
<!--            <legend class="sr-only">Notifications</legend>-->
<!--            <div class="relative flex items-start justify-between">-->
<!--              <div class="flex">-->
<!--                <div class="flex items-center h-5">-->
<!--                  <input :id="data.name" v-bind:checked="data.check" aria-describedby="comments-description" :name="data.name" type="checkbox"-->
<!--                         class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"/>-->
<!--                </div>-->
<!--                <div class="ml-3 text-sm">-->
<!--                  <label :for="data.name" class="font-medium text-gray-500">{{ data.name }}</label>-->
<!--                </div>-->
<!--              </div>-->

<!--              <div>-->
<!--                  <span-->
<!--                      class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-indigo-100 text-indigo-800">-->
<!--                    <svg class="mr-1.5 h-2 w-2 text-indigo-400" fill="currentColor" viewBox="0 0 8 8">-->
<!--                      <circle cx="4" cy="4" r="3"/>-->
<!--                    </svg>-->
<!--                    {{ data.nombre }}-->
<!--                  </span>-->
<!--              </div>-->
<!--            </div>-->
<!--          </fieldset>-->
<!--        </nav>-->
<!--      </div>-->

      <!--      Block pour les taille d'écran sm et moins -->

      <!--      Div de droite-->
      <div class=" flex-3 p-4">
        <!--        invisible pour les petites taille d'écran-->
        <p class="text-xl mb-5 hidden md:block">
          ({{ state.numberOfJob }}) Offres d'emplois
        </p>
        <!--        Visible avec les taille d'écran md en montant-->
        <div class="justify-between flex md:hidden mb-5">
          <div class="text-base">
            <h3>({{ state.numberOfJob }}) Offres d'emplois</h3>
          </div>

<!--          <div class="flex space-x-2 cursor-pointer">-->
<!--            <AdjustmentsIcon class="w-5 transform rotate-90 text-red-400"/>-->
<!--            <h3 class="text-red-400">-->
<!--              Filters-->
<!--            </h3>-->
<!--          </div>-->
        </div>
<!--        <div v-bind:style="{background:'#F7F9FD'}" class="rounded md:hidden p-4">-->
<!--          <header>-->
<!--            <h3 class="font-medium tracking-wider text-gray-500">Compétences</h3>-->
<!--            <div>-->
<!--              <input disabled v-bind:style="{background:'#EBF0F9'}" type="email" name="email" id="email"-->
<!--                     class="shadow-sm text-gray-600 block md:text-base w-full h-12 sm:text-sm border-gray-300 rounded focus:ring-indigo-500 focus:border-indigo-500"-->
<!--                     placeholder="Ex: Java..."/>-->
<!--            </div>-->
<!--            <hr class="w-full h-0.5 bg-indigo-500 mt-3">-->
<!--          </header>-->
<!--          <nav class="mt-5">-->
<!--            <h3 class="font-medium tracking-tighter text-gray-500">Type d'emploie</h3>-->
<!--            <fieldset v-for="(data,index) in state.Type_Offre" :key="index" class="space-y-5">-->
<!--              <legend class="sr-only">Notifications</legend>-->
<!--              <div class="relative flex items-start justify-between">-->
<!--                <div class="flex">-->
<!--                  <div class="flex items-center h-5">-->
<!--                    <input v-bind:checked="data.check" id="comments3" aria-describedby="comments-description" name="comments3" type="checkbox"-->
<!--                           class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"/>-->
<!--                  </div>-->
<!--                  <div class="ml-3 text-sm">-->
<!--                    <label for="comments3" class="font-medium text-gray-500">{{ data.name }}</label>-->
<!--                  </div>-->
<!--                </div>-->

<!--                <div>-->
<!--                  <span-->
<!--                      class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-indigo-100 text-indigo-800">-->
<!--                    <svg class="mr-1.5 h-2 w-2 text-indigo-400" fill="currentColor" viewBox="0 0 8 8">-->
<!--                      <circle cx="4" cy="4" r="3"/>-->
<!--                    </svg>-->
<!--                    {{data.nombre}}-->
<!--                  </span>-->
<!--                </div>-->
<!--              </div>-->
<!--            </fieldset>-->
<!--          </nav>-->
<!--        </div>-->

        <div v-for="(data,index) in state.datas" :key="index"
             class="mt-2 w-full border shadow-md border-2 border-gray-200 rounded p-6">
          <div class="flex justify-between items-center">
            <div class="flex justify-around items-center space-x-3">
<!--              <div>-->
<!--                <img class="w-10 h-10" :src="state.logo" alt="">-->
<!--              </div>-->
              <div>
                <h2 class="text-black text-lg font-medium tracking-wider">
                  <router-link :to="{name:'detail', params:{id:data.id}}">
                  {{ data.name }}
                  </router-link>
                </h2>
                <h3 class="text-red-500 tracking-widest mt-2">NEXAH</h3>
              </div>
            </div>
            <div v-if="data.expire===1" class="flex justify-around items-center space-x-1  text-blue-800 cursor-pointer">
              <button class="tracking-widest">
                <router-link :to="{name:'detail', params:{id:data.id}}">
                  Postuler
                </router-link>
              </button>
              <ArrowSmRightIcon class="w-4 h-4"/>
            </div>
            <div v-else class="flex justify-around items-center space-x-1  text-red-500 cursor-pointer">
              <button class="tracking-widest">
                <router-link :to="{name:'detail', params:{id:data.id}}">
                  Expiré
                </router-link>
              </button>
              <BanIcon class="w-4 h-4"/>
            </div>
          </div>
          <div>
            <div>
              <p class="mt-3 text-gray-700 text-sm">Département > {{ data.department.name }}</p>
            </div>
            <div class="mt-5 flex space-x-4">
              <fieldset class="flex items-center space-x-1">
                <div>
                  <BriefcaseIcon class="sm:w-5 sm:h-5 xs:w-4 xs:h-4 text-gray-600"/>
                </div>
                <div>
                  <h3 class="text-gray-400 sm:text-base xs:text-xs">{{ data.offer_type }}</h3>
                </div>
              </fieldset>
              <fieldset class="flex items-center space-x-1">
                <div>
                  <ClockIcon class="sm:w-5 sm:h-5 xs:w-4 xs:h-4 text-gray-600"/>
                </div>
                <div>
                  <h3 class="text-gray-400 sm:text-base xs:text-xs">Delais : {{ data.deadline.split(' ')[0] }}</h3>
                </div>
              </fieldset>
              <fieldset class="flex items-center space-x-1">
                <div>
                  <LocationMarkerIcon class="sm:w-5 sm:h-5 xs:w-4 xs:h-4 text-gray-600"/>
                </div>
                <div>
                  <h3 class="text-gray-400 sm:text-base xs:text-xs">{{ data.town }}</h3>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      </div>
    </div>




      <div v-bind:style="{background:'#191919'}" class="h-16 flex justify-center items-center">
        <h3 class="text-white text-center ">Copyright 2021 Nexah ®</h3>
      </div>
  </div>


    <div v-if="state.showDialog2" class="fixed inset-0">
      <DialogCard  v-on:clickNeg="clickNegDialog"
                   :message="'Bientôt Disponible'"
                   class="mt-20 mx-auto"/>
    </div>
  </div>

</template>

<script>
import ResearchComponent from "@/components/ResearchComponent";
import {reactive, onMounted} from "vue";
import logo from '../assets/img.png'
import {
  ArrowSmRightIcon, BriefcaseIcon,BanIcon,
  ClockIcon, LocationMarkerIcon, AdjustmentsIcon
} from '@heroicons/vue/outline'
import axios from "axios";
import LoaderComponent from '@/components/LoaderComponent'
import HeaderComponent from "@/components/HeaderComponent";
import DialogCard from "@/components/DialogCard";

export default {
  name: 'Home',
  setup() {
    const state = reactive({
      logo,
      datas: null,
      numberOfJob: 0,
      jobSearch : '',
      showDialog2 :false,
      Type_Offre: [
        {name: 'CDI', nombre : '01', check:true}

      ]
    })

    onMounted(() => {
      var Base64={_keyStr:"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",encode:function(e){var t="";var n,r,i,s,o,u,a;var f=0;e=Base64._utf8_encode(e);while(f<e.length){n=e.charCodeAt(f++);r=e.charCodeAt(f++);i=e.charCodeAt(f++);s=n>>2;o=(n&3)<<4|r>>4;u=(r&15)<<2|i>>6;a=i&63;if(isNaN(r)){u=a=64}else if(isNaN(i)){a=64}t=t+this._keyStr.charAt(s)+this._keyStr.charAt(o)+this._keyStr.charAt(u)+this._keyStr.charAt(a)}return t},decode:function(e){var t="";var n,r,i;var s,o,u,a;var f=0;e=e.replace(/[^A-Za-z0-9\+\/\=]/g,"");while(f<e.length){s=this._keyStr.indexOf(e.charAt(f++));o=this._keyStr.indexOf(e.charAt(f++));u=this._keyStr.indexOf(e.charAt(f++));a=this._keyStr.indexOf(e.charAt(f++));n=s<<2|o>>4;r=(o&15)<<4|u>>2;i=(u&3)<<6|a;t=t+String.fromCharCode(n);if(u!=64){t=t+String.fromCharCode(r)}if(a!=64){t=t+String.fromCharCode(i)}}t=Base64._utf8_decode(t);return t},_utf8_encode:function(e){e=e.replace(/\r\n/g,"\n");var t="";for(var n=0;n<e.length;n++){var r=e.charCodeAt(n);if(r<128){t+=String.fromCharCode(r)}else if(r>127&&r<2048){t+=String.fromCharCode(r>>6|192);t+=String.fromCharCode(r&63|128)}else{t+=String.fromCharCode(r>>12|224);t+=String.fromCharCode(r>>6&63|128);t+=String.fromCharCode(r&63|128)}}return t},_utf8_decode:function(e){var t="";var n=0;var r=c1=c2=0;while(n<e.length){r=e.charCodeAt(n);if(r<128){t+=String.fromCharCode(r);n++}else if(r>191&&r<224){c2=e.charCodeAt(n+1);t+=String.fromCharCode((r&31)<<6|c2&63);n+=2}else{c2=e.charCodeAt(n+1);c3=e.charCodeAt(n+2);t+=String.fromCharCode((r&15)<<12|(c2&63)<<6|c3&63);n+=3}}return t}}

      axios.get('https://ms-careers.nexah.net/api/v1/offer-department', {
        headers: {
          'Authorization': Base64.encode("9:NEX@H")
        }
      }).then(response => {
        console.log('OKKKKKKKKKKKK  '+response.data.data.data)
        let mydata = response.data.data.data
        console.log(response.data.data.data)
        state.numberOfJob = mydata.length
        state.datas = mydata
      })
    })

    function clickNegDialog(e){
      state.showDialog2 = e
    }

    function getValueForm(e){
      state.jobSearch = e
      console.log(`state ${state.jobSearch}`)
    }

    return {
      state,
      getValueForm,
      clickNegDialog
    }
  },
  components: {
    ResearchComponent,
    ArrowSmRightIcon,
    BriefcaseIcon,
    ClockIcon,
    LocationMarkerIcon,
    AdjustmentsIcon,
    LoaderComponent,
    HeaderComponent,
    DialogCard,
    BanIcon
  }
}
</script>
